<template>
  <div class="user-details">
    <!--begin: User Details Card-->
    <b-card class="mt-4">
      <!--begin: Card Header-->
      <b-card-header class="border-0 px-0 pt-0">
        <!--begin: Profile Background-->
        <div
          class="d-flex justify-content-start align-items-end pl-5 pb-3 profile-background"
          :style="`background-image: url('${
            globalProfileBackgroundUrl
              ? globalProfileBackgroundUrl
              : dummyProfileBackgroundUrl
          }');`"
        >
          <!--begin: Profile Photo-->
          <b-img
            rounded="circle"
            class="profile-image"
            :src="
              globalProfilePhotoUrl
                ? globalProfilePhotoUrl
                : dummyProfilePhotoUrl
            "
          >
            <!--end: Profile Photo-->
          </b-img>
          <!--end: Profile Photo-->
        </div>
        <!--end: Profile Background-->
      </b-card-header>
      <!--end: Card Header-->

      <!--begin: User Tabs-->
      <b-card-body class="pt-0 px-0">
        <b-tabs v-model="activeTabs" pills fill content-class="mt-10">
          <!--begin: Member Tab-->
          <b-tab
            :title="$t('label.member')"
            :title-link-class="{ 'cursor-default': !userObj.isCreator }"
            @click="toggleUserTab"
          >
            <template v-for="(group, groupIndex) in groupsList">
              <!--begin: Group-->
              <div :key="groupIndex">
                <!--begin: Section Title-->
                <b-card-title
                  class="text-capitalize font-weight-boldest px-5 mt-8"
                >
                  {{ $t(`label.${group}`) }}
                </b-card-title>
                <!--end: Section Title-->

                <!--begin: Details List-->
                <div
                  v-if="
                    ['personalInformation', 'accountInformation'].includes(
                      group
                    )
                  "
                >
                  <template
                    v-for="(i, index) in memberDetailList.filter(
                      (x) => x.group == groupIndex + 1
                    )"
                  >
                    <div :key="index">
                      <b-row no-gutters class="p-5">
                        <b-col cols="4">
                          <label class="text-capitalize">{{
                            $t(`label.${i.label}`)
                          }}</label>
                        </b-col>
                        <b-col cols="8">
                          <div v-if="i.key == 'isCreator'">
                            <AppMemberTypeLabel
                              :value="userObj.isCreator"
                            ></AppMemberTypeLabel>
                          </div>
                          <div v-else-if="i.key == 'bio'">
                            <span v-if="userObj.bio != '-'"
                              ><template v-for="(items, index) in userObj.bio">
                                <b-form-tag
                                  :key="index"
                                  :title="items"
                                  variant="info"
                                  no-remove
                                  class="mb-1"
                                ></b-form-tag>
                              </template>
                            </span>
                            <span v-else>{{ userObj.bio }}</span>
                          </div>
                          <!-- begin: Application Status Button -->
                          <div v-else-if="i.key == 'creatorApplicationStatus'">
                            <ProfileDetailsApplicationStatusButton
                              :userObj="userObj"
                            ></ProfileDetailsApplicationStatusButton>
                          </div>
                          <!-- end: Application Status Button -->
                          <div v-else-if="i.key == 'isActive'">
                            <AppStatusLabel
                              :value="userObj.isActive"
                              :modifiable="true"
                              :disabled="
                                !checkAccessRight(moduleName, [
                                  permissionAction.USER_UPDATE
                                ])
                              "
                              @app-status-label-action-click="statusActionClick"
                            ></AppStatusLabel>
                          </div>
                          <div
                            v-else-if="
                              i.key == 'recipeCount' && userObj.recipeCount
                            "
                          >
                            <router-link
                              :to="{
                                name: routeName.EXPERIENCE_COOKING_ALL,
                                query: {
                                  userId: id,
                                  isActive: 1
                                }
                              }"
                              class="text-hover-decoration-underline"
                            >
                              {{ userObj[i.key].active }}
                            </router-link>
                            <router-link
                              v-if="userObj[i.key].inactive"
                              :to="{
                                name: routeName.EXPERIENCE_COOKING_ALL,
                                query: {
                                  userId: id,
                                  isActive: 0
                                }
                              }"
                              class="text-hover-decoration-underline text-danger ml-1"
                              >({{ userObj[i.key].inactive }})
                            </router-link>
                          </div>
                          <span v-else class="font-size-sm">
                            {{ userObj[i.key] }}
                          </span>
                        </b-col>
                      </b-row>
                      <div class="separator separator-solid"></div>
                    </div>
                  </template>
                </div>

                <!--begin: Referral Details-->
                <div v-else-if="group == 'referral'">
                  <ProfileDetailsReferralDetails
                    :value="userObj"
                  ></ProfileDetailsReferralDetails>
                </div>
                <!--end: Referral Details-->

                <!--begin: Member Address Book Table-->
                <div v-else>
                  <AppSmartTable
                    :items="userAddressBooks.items"
                    :headers="userAddressBookTableHeader"
                    :pagination="userAddressBooks.pagination"
                    @app-smart-table-pagination-change="actionPaginationChange"
                    @app-table-read-details="
                      actionUserAddressBookTableReadDetails
                    "
                  ></AppSmartTable>
                </div>
                <!--end: Member Address Book Table-->

                <!--end: Details List-->
              </div>
              <!--end: Group-->
            </template>
          </b-tab>
          <!--end: Member Tab-->

          <!--begin: Creator Tab-->
          <b-tab
            v-if="userObj.isCreator"
            :title="$t('label.creator')"
            :disabled="!userObj.isCreator"
            @click="toggleCreatorTab"
          >
            <UserCreatorProfileDetails ref="UserCreatorProfileDetails" :id="id">
            </UserCreatorProfileDetails>
          </b-tab>
          <!--end: Creator Tab-->
        </b-tabs>
      </b-card-body>
      <!--end: User Tabs-->
    </b-card>
    <!--end: User Details Card-->
    <!--begin: Creator Voucher Card-->
    <UserCreatorVoucherCard
      v-if="isShowCreator"
      :id="id"
      class="mt-4"
    ></UserCreatorVoucherCard>
    <!--end: Creator Voucher Card-->

    <!--begin: User Voucher Card-->
    <UserVoucherCard v-else :id="id" class="mt-4"></UserVoucherCard>
    <!--end: User Voucher Card-->

    <!--begin: Profile Details Details Audit Log Table-->
    <AuditLogTable
      v-if="isAllowReadAuditLog"
      ref="auditLogTable"
      :type="auditLogType.USER"
      :modelId="Number(id)"
      class="mt-4"
    ></AuditLogTable>
    <!--end: Profile Details Details Audit Log Table-->

    <!--begin: App Modal Description List-->
    <AppModalDescriptionList
      v-model="appModalDescriptionListObj.show"
      :title="appModalDescriptionListObj.title"
      :descriptions="appModalDescriptionListObj.descriptions"
      :dataObject="appModalDescriptionListObj.dataObject"
    ></AppModalDescriptionList>
    <!--end: App Modal Description List-->
  </div>
</template>

<script>
  import userMixin from '@/core/mixins/user.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import AppStatusLabel from '@/views/components/bases/AppStatusLabel';
  import AppMemberTypeLabel from '@/views/components/bases/AppMemberTypeLabel';
  import AppModalDescriptionList from '@/views/components/bases/modal/AppModalDescriptionList';
  import UserCreatorProfileDetails from '@/views/components/user/UserCreatorProfileDetails';
  import ProfileDetailsApplicationStatusButton from '@/views/components/profile-details/ProfileDetailsApplicationStatusButton';
  import ProfileDetailsReferralDetails from '@/views/components/profile-details/ProfileDetailsReferralDetails';
  import AuditLogTable from '@/views/components/audit-log/AuditLogTable';
  import UserVoucherCard from '@/views/components/user/UserVoucherCard';
  import UserCreatorVoucherCard from '@/views/components/user/UserCreatorVoucherCard';
  import {
    CreatorApplicationStatus,
    AUDIT_LOG_TYPE
  } from '@/core/constants/enums';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    USER_GET_USER,
    USER_GET_USER_ADDRESS_BOOKS,
    USER_GET_USER_ADDRESS_BOOK,
    USER_INITIAL_GET_USER_STATE,
    USER_INITIAL_GET_USER_ADDRESS_BOOKS,
    USER_INITIAL_GET_USER_ADDRESS_BOOK
  } from '@/core/store/user.module';

  export default {
    name: 'ProfileDetailsDetails',
    components: {
      AppSmartTable,
      AppStatusLabel,
      AppMemberTypeLabel,
      AppModalDescriptionList,
      UserCreatorProfileDetails,
      AuditLogTable,
      ProfileDetailsApplicationStatusButton,
      ProfileDetailsReferralDetails,
      UserVoucherCard,
      UserCreatorVoucherCard
    },
    mixins: [commonMixin, userMixin],
    data: () => ({
      routeName: ROUTE_NAME,
      moduleName: MODULE.USER,
      permissionAction: ACTION,
      module: MODULE,
      id: null,
      isShowCreator: false,
      userObj: {
        id: null,
        username: null,
        email: null,
        contactNumber: null,
        phoneVerifiedAt: null,
        isActive: null,
        isCreator: null,
        lastLoginAt: null,
        accountDeletedAt: null,
        bio: null,
        birthday: null,
        profilePhotoUrl: null,
        profileBackgroundUrl: null,
        creatorApplicationStatus: {
          value: 1,
          name: ''
        }
      },
      userAddressBooks: {
        items: [],
        pagination: {
          currentPage: 1,
          total: 1,
          perPage: 10,
          lastPage: 1
        }
      },
      userAddressBookTableHeader: [
        {
          key: 'no',
          name: 'no'
        },
        {
          key: 'placeName',
          name: 'placeName',
          sortable: true
        },
        {
          key: 'contactName',
          name: 'contactName',
          sortable: true
        },
        {
          key: 'isDefault',
          name: 'isDefault',
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'address',
          name: 'address',
          sortable: true
        },
        {
          key: 'createdAt',
          name: 'createdAt',
          sortable: true
        },
        {
          key: 'updatedAt',
          name: 'updatedAt',
          sortable: true
        }
        // {
        //   key: 'actions',
        //   name: 'actions',
        //   active: true,
        //   action: true,
        //   layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS
        // }
      ],
      searchCriteriaUserAddressBook: {
        perPage: 10,
        page: 1
      },
      userAddressBookObj: {
        data: {},
        modal: false
      },
      groupsList: [
        'personalInformation',
        'accountInformation',
        'referral',
        'addressBook'
      ],
      memberDetailList: [
        {
          label: 'memberType',
          key: 'isCreator',
          group: 1
        },
        {
          label: 'username',
          key: 'username',
          group: 1
        },
        {
          label: 'displayName',
          key: 'displayName',
          group: 1
        },
        {
          label: 'bio',
          key: 'bio',
          group: 1
        },
        {
          label: 'aboutMe',
          key: 'aboutMe',
          group: 1
        },
        {
          label: 'dateOfBirth',
          key: 'birthday',
          group: 1
        },
        {
          label: 'contactNumber',
          key: 'contactNumber',
          group: 1
        },
        {
          label: 'email',
          key: 'email',
          group: 1
        },
        // {
        //   label: 'foodTypePreferences',
        //   key: 'foodTypePreferences',
        //   group: 1
        // },
        {
          label: 'following',
          key: 'followingCount',
          group: 1
        },
        {
          label: 'experienceCooking',
          key: 'recipeCount',
          group: 1
        },
        {
          label: 'status',
          key: 'isActive',
          group: 2
        },
        {
          label: 'creatorApplicationStatus',
          key: 'creatorApplicationStatus',
          group: 2
        },
        {
          label: 'contactNumberVerifiedDateTime',
          key: 'phoneVerifiedAt',
          group: 2
        },
        {
          label: 'registerDateTime',
          key: 'createdAt',
          group: 2
        },
        {
          label: 'registerPlatform',
          key: 'registerPlatform',
          group: 2
        },
        {
          label: 'platformUsing',
          key: 'platformUsing',
          group: 2
        },
        {
          label: 'lastLoginDateTime',
          key: 'lastLoginAt',
          group: 2
        },
        {
          label: 'accountDeleteDateTime',
          key: 'accountDeletedAt',
          group: 2
        }
      ],
      userAddressBookDescriptions: [
        {
          label: 'placeName',
          key: 'placeName'
        },
        {
          label: 'contactName',
          key: 'contactName'
        },
        {
          label: 'contactNumber',
          key: 'contactNumber'
        },
        {
          label: 'postcode',
          key: 'postcode'
        },
        {
          label: 'city',
          key: 'city'
        },
        {
          label: 'state',
          key: 'state'
        },
        {
          label: 'address',
          key: 'address'
        },
        {
          label: 'noteToRider',
          key: 'noteToRider'
        },
        {
          label: 'isDefault',
          key: 'isDefault',
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          label: 'createdAt',
          key: 'createdAt'
        },
        {
          label: 'updatedAt',
          key: 'updatedAt'
        }
      ],
      appModalDescriptionListObj: {
        show: false,
        title: '',
        descriptions: [],
        dataObject: {}
      },
      auditLogType: AUDIT_LOG_TYPE,
      globalProfilePhotoUrl: '',
      globalProfileBackgroundUrl: '',
      activeTabs: 0
    }),
    computed: {
      userComplete() {
        return this.$store.state.user.user.complete;
      },
      userAddressBooksComplete() {
        return this.$store.state.user.userAddressBooks.complete;
      },
      userAddressBookComplete() {
        return this.$store.state.user.userAddressBook.complete;
      },
      dummyProfileBackgroundUrl() {
        return '/media/stock-900x600/34.jpg';
      },
      dummyProfilePhotoUrl() {
        return '/media/users/300_21.jpg';
      },
      isAllowReadAuditLog() {
        return this.checkAccessRight(MODULE.AUDIT_LOG, [ACTION.AUDIT_LOG_READ]);
      },
      route() {
        return this.$route;
      }
    },
    watch: {
      userComplete() {
        let response = this.$store.state.user.user;
        if (response.complete) {
          this.getUserCompleteAction(response);
        }
      },
      updateUserActiveComplete() {
        let response = this.$store.state.user.updateUserActive;
        if (response.complete) {
          this.updateUserActiveCompleteAction(response);
        }
      },
      userAddressBooksComplete() {
        let response = this.$store.state.user.userAddressBooks;
        if (response.complete) {
          this.getUserAddressBooksCompleteAction(response);
        }
      },
      userAddressBookComplete() {
        let response = this.$store.state.user.userAddressBook;
        if (response.complete) {
          this.getUserAddressBookCompleteAction(response);
        }
      },
      activeTabs() {
        let data = this.userObj;

        if (this.activeTabs == 0) {
          this.importHeaderImages(
            data.profilePhotoUrl,
            data.profileBackgroundUrl
          );
        }
      },
      route() {
        this.initialUserDetails();
      }
    },
    created() {
      this.initialUserDetails();
    },
    methods: {
      getUserAddressBookCompleteAction(response) {
        let title = i18nHelper.getMessage('label.memberAddressBook');

        if (response.code == 0) {
          this.appModalDescriptionListObj = {
            show: true,
            title: title,
            descriptions: this.userAddressBookDescriptions,
            dataObject: response.data
          };
        } else {
          this.openErrorLayoutAppModalInfo(title, response);
        }
        this.initialGetUserAddressBook();
      },
      getUserAddressBooksCompleteAction(response) {
        let title = i18nHelper.getMessage('label.memberAddressBook');

        if (response.code == 0) {
          this.userAddressBooks = {
            items: response.data,
            pagination: response.pagination
          };
        } else {
          this.openErrorLayoutAppModalInfo(title, response);
        }
        this.initialGetUserAddressBooks();
      },
      updateUserActiveCompleteAction(response) {
        let title = i18nHelper.getMessage('label.updateUserActiveStatus');
        let messages = response.message;
        let buttons = [
          {
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.getUser(this.id);
              this.initialUpdateUserActiveState();
              this.closeLayoutAppModalInfo();
              if (this.isAllowReadAuditLog)
                this.$refs.auditLogTable.initAuditLogTable();
            }
          }
        ];

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      getUserCompleteAction(response) {
        let data = response.data;
        let title = i18nHelper.getMessage('label.userDetails');
        let messages = response.message;
        let buttons = [
          {
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          }
        ];

        if (response.code == 0) {
          this.userObj = data;
          this.importHeaderImages(
            data.profilePhotoUrl,
            data.profileBackgroundUrl
          );
        } else {
          this.openLayoutAppModalInfo(title, messages, buttons);
        }
        this.initialGetUserState();
      },
      importHeaderImages(photo, background) {
        this.globalProfilePhotoUrl = photo;
        this.globalProfileBackgroundUrl = background;
      },
      toggleCreatorTab() {
        this.isShowCreator = true;
        this.$refs.UserCreatorProfileDetails.getCreatorProfile();
      },
      toggleUserTab() {
        this.isShowCreator = false;
      },
      statusActionClick(value) {
        this.openRequestUpdateUserActiveStatusModal(this.id, value, 'user');
      },
      actionUserAddressBookTableReadDetails(data) {
        this.getUserAddressBook(this.id, data);
      },
      actionPaginationChange(value) {
        this.searchCriteriaUserAddressBook = {
          perPage: value.size,
          page: value.page
        };

        this.getUserAddressBooks(this.id, this.searchCriteriaUserAddressBook);
      },
      creatorApplicationStatusColor(value) {
        let color = {
          [CreatorApplicationStatus.NOT_APPLY]: () => 'primary',
          [CreatorApplicationStatus.PENDING]: () => 'warning',
          [CreatorApplicationStatus.APPROVED]: () => 'success',
          [CreatorApplicationStatus.REJECTED]: () => 'danger'
        };

        return color[value]();
      },
      getUser(id) {
        this.$store.dispatch(USER_GET_USER, { id });
      },
      getUserAddressBooks(id, data) {
        this.$store.dispatch(USER_GET_USER_ADDRESS_BOOKS, { id, data });
      },
      getUserAddressBook(userId, addressBookId) {
        this.$store.dispatch(USER_GET_USER_ADDRESS_BOOK, {
          userId,
          addressBookId
        });
      },
      initialGetUserState() {
        this.$store.dispatch(USER_INITIAL_GET_USER_STATE);
      },
      initialGetUserAddressBooks() {
        this.$store.dispatch(USER_INITIAL_GET_USER_ADDRESS_BOOKS);
      },
      initialGetUserAddressBook() {
        this.$store.dispatch(USER_INITIAL_GET_USER_ADDRESS_BOOK);
      },
      initialUserDetails() {
        let id = this.$route.params.id;
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.profileDetails'),
              route: { name: ROUTE_NAME.PROFILE_DETAILS }
            },
            { title: i18nHelper.getMessage('label.profileDetailsDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getUser(id);
        this.getUserAddressBooks(id, this.searchCriteriaUserAddressBook);
        this.initBreadCrumb(breadcrumbData);
      }
    }
  };
</script>

<style lang="scss">
  .user-details {
    .profile-background {
      height: 18rem;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      .profile-image {
        width: 150px;
        height: 150px;
        border: 5px solid #e4e6ef;
        box-shadow: 0 2px 2px rgb(0 0 0 / 50%);
        object-fit: contain;
        background-color: #ffffff;
      }
    }
  }

  @media (max-width: 1263px) {
    .user-details .profile-background .profile-image {
      width: 140px;
      height: 140px;
    }
  }

  @media (max-width: 959px) {
    .user-details .profile-background .profile-image {
      width: 120px;
      height: 120px;
    }
  }
</style>
